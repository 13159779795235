import React from 'react';
import BlockHomeFirst from '../block-home-first';
import BlockHomeThird from '../block-home-third';
import BlockHomeSecond from '../block-home-second';

export const MainSection = () => {

  return (
    <>
    <BlockHomeFirst/>
    <BlockHomeSecond/>
    <BlockHomeThird/>
    </>
  )
}

